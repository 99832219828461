import React from "react";
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Select,
  Stack,
  Button,
  Box,
  Image,
  HStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Field, Form, useFormikContext } from "formik";
import FormStepper from "./FormStepper";
import PhoneNumberInput from "./PhoneNumberInput";
import { businessCategories } from "../constants/businessCategories";
import BusinessCard from "./BusinessCard";
import { useLanguage } from "../contexts/LanguageContext";

export const steps = [
  { title: "businessForm.steps.contact" },
  { title: "businessForm.steps.businessInfo" },
  { title: "businessForm.steps.coupon" },
  { title: "businessForm.steps.preview" },
];

const BusinessFormContent = ({
  activeStep,
  setActiveStep,
  handleLogoChange,
  logoPreview,
  setLogoPreview,
  isEditMode,
  isSubmitting,
}) => {
  const { language, setLanguage, translate } = useLanguage();
  const { values, setFieldValue } = useFormikContext();

  const handleCategoryChange = (selectedEnglishCategory) => {
    const selectedCategory = businessCategories.find(
      (category) => category.en === selectedEnglishCategory
    );

    if (selectedCategory) {
      setFieldValue("category", {
        en: selectedCategory.en,
        fr: selectedCategory.fr,
      });
    }
  };

  const PreviewStep = () => {
    const previewData = {
      name: values.name[language] || values.name.en,
      category: values.category[language] || values.category.en,
      imageUrl: logoPreview,
      description:
        values.description?.[language] || values.description?.en || "",
      websiteUrl: values.websiteUrl,
      googleProfileUrl: values.googleProfileUrl,
      coupon: {
        title: values.offerTitle[language] || values.offerTitle.en,
        description:
          values.offerDescription[language] || values.offerDescription.en,
        termsAndExclusions:
          values.termsAndExclusions?.[language] ||
          values.termsAndExclusions?.en,
        maxValue: values.maxValue,
      },
    };

    const translations = {
      language,
      visitWebsite: translate("businessList.website"),
      googleProfile: translate("businessList.googleProfile"),
      termsAndExclusions: translate("businessList.termsAndExclusions"),
      maxValue: translate("businessList.maxValue"),
    };

    return (
      <VStack spacing={6} width="100%" align="stretch">
        {/* <Text fontSize="md" fontWeight="medium">
          {translate("businessForm.preview.description")}
        </Text> */}
        <Flex align="center" justify="center">
          <Box
            as="button"
            py={2}
            px={2}
            onClick={() => setLanguage("en")}
            color={language === "en" ? "black" : "gray.500"}
            fontWeight={language === "en" ? "bold" : "normal"}
          >
            EN
          </Box>
          <Box px={0.5} color="gray.300">
            |
          </Box>
          <Box
            as="button"
            py={2}
            px={2}
            onClick={() => setLanguage("fr")}
            color={language === "fr" ? "black" : "gray.500"}
            fontWeight={language === "fr" ? "bold" : "normal"}
          >
            FR
          </Box>
        </Flex>

        <Box maxW="500px" mx="auto">
          <BusinessCard business={previewData} translations={translations} />
        </Box>
      </VStack>
    );
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0: // Contact
        return (
          <VStack spacing={6} width="100%">
            <Text fontSize="md" fontWeight="medium" mb={4}>
              {translate("businessForm.contact.description")}
            </Text>

            <Field name="contactName">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.contactName && form.touched.contactName
                  }
                  isRequired
                >
                  <FormLabel fontSize="sm">
                    {translate("businessForm.contact.contactName.label")}
                  </FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.contactName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  isRequired
                >
                  <FormLabel fontSize="sm">
                    {translate("businessForm.contact.email.label")}
                  </FormLabel>
                  <Input {...field} type="email" />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="phoneNumber">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.phoneNumber && form.touched.phoneNumber
                  }
                  isRequired
                >
                  <FormLabel fontSize="sm">
                    {translate("businessForm.contact.phoneNumber.label")}
                  </FormLabel>
                  <PhoneNumberInput field={field} />
                  <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </VStack>
        );

      case 1: // Business Info
        return (
          <VStack spacing={6} width="100%">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="name.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name?.en && form.touched.name?.en}
                    isRequired
                  >
                    <FormLabel fontSize="sm">
                      {translate(
                        "businessForm.businessInfo.businessName.english.label"
                      )}
                    </FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{form.errors.name?.en}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="name.fr">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name?.fr && form.touched.name?.fr}
                  >
                    <FormLabel fontSize="sm">
                      {translate(
                        "businessForm.businessInfo.businessName.french.label"
                      )}
                    </FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{form.errors.name?.fr}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Field name="category.en">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.category?.en && form.touched.category?.en
                  }
                  isRequired
                >
                  <FormLabel fontSize="sm">
                    {translate("businessForm.businessInfo.category.label")}
                  </FormLabel>
                  <Select
                    {...field}
                    placeholder={translate(
                      "businessForm.businessInfo.category.placeholder"
                    )}
                    onChange={(e) => {
                      handleCategoryChange(e.target.value);
                    }}
                    value={values.category.en || ""}
                  >
                    {businessCategories.map((category) => (
                      <option key={category.en} value={category.en}>
                        {`${category.en} / ${category.fr}`}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {form.errors.category?.en}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="websiteUrl">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.websiteUrl && form.touched.websiteUrl
                    }
                  >
                    <FormLabel fontSize="sm">
                      {translate("businessForm.businessInfo.websiteUrl.label")}
                    </FormLabel>
                    <Input {...field} type="url" />
                    <FormErrorMessage>
                      {form.errors.websiteUrl}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="googleProfileUrl">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.googleProfileUrl &&
                      form.touched.googleProfileUrl
                    }
                  >
                    <FormLabel fontSize="sm">
                      {translate(
                        "businessForm.businessInfo.googleProfileUrl.label"
                      )}
                    </FormLabel>
                    <Input {...field} type="url" />
                    <FormErrorMessage>
                      {form.errors.googleProfileUrl}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <FormControl>
              <FormLabel fontSize="sm">
                {translate("businessForm.businessInfo.logo.label")}
              </FormLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                display="none"
                id="logo-upload"
              />
              <Button
                as="label"
                htmlFor="logo-upload"
                cursor="pointer"
                width="100%"
              >
                {translate("businessForm.businessInfo.logo.uploadButton")}
              </Button>
              {logoPreview && (
                <Box mt={2}>
                  <Image
                    src={logoPreview}
                    alt="Logo preview"
                    maxH="100px"
                    objectFit="contain"
                  />
                </Box>
              )}
            </FormControl>
          </VStack>
        );

      case 2: // Coupon
        return (
          <VStack spacing={6} width="100%">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="offerTitle.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.offerTitle?.en && form.touched.offerTitle?.en
                    }
                    isRequired
                  >
                    <FormLabel fontSize="sm">
                      {translate(
                        "businessForm.coupon.offerTitle.english.label"
                      )}
                    </FormLabel>
                    <Input
                      {...field}
                      placeholder={translate(
                        "businessForm.coupon.offerTitle.english.placeholder"
                      )}
                    />
                    <FormErrorMessage>
                      {form.errors.offerTitle?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="offerTitle.fr">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.offerTitle?.fr && form.touched.offerTitle?.fr
                    }
                  >
                    <FormLabel fontSize="sm">
                      {translate("businessForm.coupon.offerTitle.french.label")}
                    </FormLabel>
                    <Input
                      {...field}
                      placeholder={translate(
                        "businessForm.coupon.offerTitle.french.placeholder"
                      )}
                    />
                    <FormErrorMessage>
                      {form.errors.offerTitle?.fr}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="offerDescription.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.offerDescription?.en &&
                      form.touched.offerDescription?.en
                    }
                    isRequired
                  >
                    <FormLabel fontSize="sm">
                      {translate(
                        "businessForm.coupon.offerDescription.english.label"
                      )}
                    </FormLabel>
                    <Textarea
                      {...field}
                      placeholder={translate(
                        "businessForm.coupon.offerDescription.english.placeholder"
                      )}
                    />
                    <FormErrorMessage>
                      {form.errors.offerDescription?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="offerDescription.fr">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.offerDescription?.fr &&
                      form.touched.offerDescription?.fr
                    }
                  >
                    <FormLabel fontSize="sm">
                      {translate(
                        "businessForm.coupon.offerDescription.french.label"
                      )}
                    </FormLabel>
                    <Textarea
                      {...field}
                      placeholder={translate(
                        "businessForm.coupon.offerDescription.french.placeholder"
                      )}
                    />
                    <FormErrorMessage>
                      {form.errors.offerDescription?.fr}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Field name="maxValue">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.maxValue && form.touched.maxValue}
                >
                  <FormLabel fontSize="sm">
                    {translate("businessForm.coupon.maxValue.label")}
                  </FormLabel>
                  <Input
                    {...field}
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder={translate(
                      "businessForm.coupon.maxValue.placeholder"
                    )}
                  />
                  <FormErrorMessage>{form.errors.maxValue}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={6}
              width="100%"
            >
              <Field name="termsAndExclusions.en">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.termsAndExclusions?.en &&
                      form.touched.termsAndExclusions?.en
                    }
                  >
                    <FormLabel fontSize="sm">
                      {translate(
                        "businessForm.coupon.termsAndExclusions.english.label"
                      )}
                    </FormLabel>
                    <Textarea
                      {...field}
                      placeholder={translate(
                        "businessForm.coupon.termsAndExclusions.english.placeholder"
                      )}
                    />
                    <FormErrorMessage>
                      {form.errors.termsAndExclusions?.en}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="termsAndExclusions.fr">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.termsAndExclusions?.fr &&
                      form.touched.termsAndExclusions?.fr
                    }
                  >
                    <FormLabel fontSize="sm">
                      {translate(
                        "businessForm.coupon.termsAndExclusions.french.label"
                      )}
                    </FormLabel>
                    <Textarea
                      {...field}
                      placeholder={translate(
                        "businessForm.coupon.termsAndExclusions.french.placeholder"
                      )}
                    />
                    <FormErrorMessage>
                      {form.errors.termsAndExclusions?.fr}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </VStack>
        );

      case 3: // Preview
        return <PreviewStep />;

      default:
        return null;
    }
  };

  return (
    <Form>
      <VStack spacing={{ base: 6, md: 8 }} width="100%">
        <FormStepper steps={steps} activeStep={activeStep} />
        <Box width="100%" pt={{ base: 4, md: 10 }}>
          {renderStepContent(activeStep)}
        </Box>
        <HStack
          justify="space-between"
          width="100%"
          pt={{ base: 4, md: 6 }}
          flexDir={{ base: "column", sm: "row" }}
          spacing={{ base: 4, sm: 0 }}
        >
          <Button
            variant="ghost"
            onClick={() => setActiveStep(Math.max(activeStep - 1, 0))}
            isDisabled={activeStep === 0}
            width={{ base: "100%", sm: "auto" }}
          >
            {translate("businessForm.navigation.previous")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            width={{ base: "100%", sm: "auto" }}
          >
            {activeStep === steps.length - 1
              ? translate("businessForm.navigation.submit")
              : translate("businessForm.navigation.next")}
          </Button>
        </HStack>
      </VStack>
    </Form>
  );
};

export default BusinessFormContent;

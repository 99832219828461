import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  Heading,
  Text,
  useToast,
  Spinner,
  Alert,
  AlertIcon,
  Box,
} from "@chakra-ui/react";
import axios from "axios";
import Navbar from "../components/Navbar";
import { apiUrl } from "../config";
import { useLanguage } from "../contexts/LanguageContext";
import BusinessFormWrapper from "../components/BusinessFormWrapper";
import TermsAlertDialog from "../components/TermsAlertDialog";

const ApprovalForm = () => {
  const { businessId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const toast = useToast();
  const { translate } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [isEligible, setIsEligible] = useState(false);
  const [businessData, setBusinessData] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(null);
  const cancelRef = React.useRef();

  useEffect(() => {
    const verifyApproval = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/verify-business-approval/${businessId}?token=${token}`
        );

        if (response.data.eligible) {
          setIsEligible(true);
          setBusinessData(response.data.businessData);
        }
      } catch (error) {
        toast({
          title: "Error",
          description:
            error.response?.data?.message || "Invalid or expired link",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsEligible(false);
      } finally {
        setIsLoading(false);
      }
    };

    verifyApproval();
  }, [businessId, token, toast]);

  const handleSubmit = async (values, actions, { logo }) => {
    setPendingSubmission({ values, actions, logo });
    setIsAlertOpen(true);
  };

  const proceedWithSubmission = async () => {
    if (!pendingSubmission) return;

    const { values, actions, logo } = pendingSubmission;
    setIsAlertOpen(false);

    try {
      const formData = new FormData();

      // Add all nested objects as stringified JSON
      formData.append("name", JSON.stringify(values.name));
      formData.append("category", JSON.stringify(values.category));
      formData.append("offerTitle", JSON.stringify(values.offerTitle));
      formData.append(
        "offerDescription",
        JSON.stringify(values.offerDescription)
      );
      formData.append(
        "termsAndExclusions",
        JSON.stringify(values.termsAndExclusions)
      );

      // Add non-nested values
      formData.append("maxValue", values.maxValue || "");
      formData.append("websiteUrl", values.websiteUrl || "");
      formData.append("googleProfileUrl", values.googleProfileUrl || "");
      formData.append("email", values.email || "");
      formData.append("phoneNumber", values.phoneNumber || "");
      formData.append("contactName", values.contactName || "");

      // Add businessId and token
      formData.append("businessId", businessId);
      formData.append("token", token);

      // Add logo if exists
      if (logo) {
        formData.append("logo", logo);
      }

      const response = await axios.post(`${apiUrl}/submit-business`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const businessPasscode = response.data.passcode;

      toast({
        title: "Success",
        description: "Business information approved successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate("/add-business-success-external/" + businessPasscode);
    } catch (error) {
      console.error("Submission error:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.error ||
          "Failed to submit business information",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    actions.setSubmitting(false);
    setPendingSubmission(null);
  };

  const onAlertClose = () => {
    setIsAlertOpen(false);
    setPendingSubmission(null);
  };

  if (isLoading) {
    return (
      <Box
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!isEligible) {
    return (
      <Box
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <Text mt={4}>
            {translate("businessForm.headers.error.invalidLink")}
          </Text>
        </Alert>
      </Box>
    );
  }

  const renderHeader = () => (
    <Heading size="lg" mb="4">
      {translate("businessForm.headers.approval.title")}
    </Heading>
  );

  return (
    <>
      <Navbar external={true} />
      <BusinessFormWrapper
        initialData={businessData}
        onSubmit={handleSubmit}
        isEditMode={false}
        renderHeader={renderHeader}
      />
      <TermsAlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        onProceed={proceedWithSubmission}
        cancelRef={cancelRef}
        useTranslation={true}
      />
    </>
  );
};

export default ApprovalForm;

import * as Yup from "yup";

export const getValidationSchemas = (translate) => [
  // Step 1: Contact
  Yup.object().shape({
    contactName: Yup.string().required(
      translate("businessForm.validation.contactName.required")
    ),
    email: Yup.string()
      .email(translate("businessForm.validation.email.invalid"))
      .required(translate("businessForm.validation.email.required")),
    phoneNumber: Yup.string()
      .matches(
        /^\d{10}$/,
        translate("businessForm.validation.phoneNumber.format")
      )
      .required(translate("businessForm.validation.phoneNumber.required")),
  }),

  // Step 2: Business Info
  Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string().required(
        translate("businessForm.validation.name.english.required")
      ),
      fr: Yup.string(),
    }),
    category: Yup.object().shape({
      en: Yup.string().required(
        translate("businessForm.validation.category.required")
      ),
      fr: Yup.string(),
    }),
    websiteUrl: Yup.string()
      .url(translate("businessForm.validation.websiteUrl.invalid"))
      .nullable()
      .default(""),
    googleProfileUrl: Yup.string()
      .url(translate("businessForm.validation.googleProfileUrl.invalid"))
      .nullable()
      .default(""),
  }),

  // Step 3: Coupon
  Yup.object().shape({
    offerTitle: Yup.object().shape({
      en: Yup.string().required(
        translate("businessForm.validation.offerTitle.english.required")
      ),
      fr: Yup.string(),
    }),
    offerDescription: Yup.object().shape({
      en: Yup.string().required(
        translate("businessForm.validation.offerDescription.english.required")
      ),
      fr: Yup.string(),
    }),
    termsAndExclusions: Yup.object().shape({
      en: Yup.string(),
      fr: Yup.string(),
    }),
    maxValue: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .typeError(translate("businessForm.validation.maxValue.invalid")),
  }),

  // Step 4: Preview
  Yup.object().shape({}),
];

export const getInitialValues = (existingData = null) => {
  const defaultValues = {
    contactName: "",
    name: { en: "", fr: "" },
    description: { en: "", fr: "" },
    websiteUrl: "",
    googleProfileUrl: "",
    category: { en: "", fr: "" },
    email: "",
    phoneNumber: "",
    offerTitle: { en: "", fr: "" },
    offerDescription: { en: "", fr: "" },
    termsAndExclusions: { en: "", fr: "" },
    maxValue: "",
    passcode: "",
    confirmPasscode: "",
  };

  if (!existingData) {
    return defaultValues;
  }

  return {
    contactName: existingData.contactName || defaultValues.contactName,
    name: existingData.name || defaultValues.name,
    description: existingData.description || defaultValues.description,
    websiteUrl: existingData.websiteUrl || defaultValues.websiteUrl,
    googleProfileUrl:
      existingData.googleProfileUrl || defaultValues.googleProfileUrl,
    category: existingData.category || defaultValues.category,
    email: existingData.email || defaultValues.email,
    phoneNumber: existingData.phoneNumber || defaultValues.phoneNumber,
    offerTitle: existingData.offerTitle || defaultValues.offerTitle,
    offerDescription:
      existingData.offerDescription || defaultValues.offerDescription,
    termsAndExclusions:
      existingData.termsAndExclusions || defaultValues.termsAndExclusions,
    maxValue: existingData.maxValue || defaultValues.maxValue,
    passcode: existingData.passcode || defaultValues.passcode,
    confirmPasscode:
      existingData.confirmPasscode ||
      existingData.passcode ||
      defaultValues.confirmPasscode,
  };
};

export const handleLogoChange = (setLogo, setLogoPreview) => (event) => {
  const file = event.target.files[0];
  if (file) {
    setLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }
};
